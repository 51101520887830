'use client'

import { type PropsWithChildren, Suspense, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { AuthModalProvider } from '../hooks/useAuthModals'
import AbTestProvider from '../providers/AbTestProvider/AbTestProvider'
import CartProvider from '../providers/CartProvider'
import { LayoutMeasurementProvider } from '../providers/LayoutMeasurementProvider/LayoutMeasurementProvider'
import LoyaltyProvider from '../providers/LoyaltyProvider'
import { PreferredSizesProvider } from '../providers/PreferredSizesProvider/PreferredSizesProvider'
import QuickAddToCartProvider from '../providers/QuickAddToCartProvider/QuickAddToCartProvider'
import ThirdPartyPaymentProvider from '../providers/ThirdPartyPaymentProvider/ThirdPartyPaymentProvider'
import WishlistProvider from '../providers/WishlistProvider/WishlistProvider'
import Loading from '../shared/Loading/Loading'
import { setupDataDog } from '~/lib/client-only/datadog'
import { getPublicConfig } from '~/lib/client-server/config'
import BfxScript from '../thirdparty/BfxScript/BfxScript'
import { Modals } from './NavbarLayout/Modals'
import dynamic from 'next/dynamic'
import { NeuePlakUA } from '~/lib/client-only/local-fonts'
import { UIComponentsProvider, type UILabelLibrary } from '@ua-digital-commerce/ua-web-components/core'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'

const Toolkit = dynamic(() => import('~/components/Toolkit/Toolkit'), { ssr: false })

interface ClientLayoutProps {
	locale: string
	footer: React.ReactElement
}

const fontConfig = {
	base: NeuePlakUA.style.fontFamily,
}

export function ClientLayout({ locale, footer, children }: PropsWithChildren<ClientLayoutProps>) {
	/**
	 * Component library needs translated text strings to render cross-locales
	 */
	const formatMessage = useFormatMessage()
	const resolveLibraryTranslations = (): UILabelLibrary => ({
		Carousel: {
			next: formatMessage('next-slide'),
			previous: formatMessage('previous-slide'),
		},
		Dialog: {
			back: formatMessage('back'),
			close: formatMessage('close-dialog'),
		},
		Button: {
			loading: formatMessage('loading-ellipsis'),
		},
		EditableCard: {
			edit: formatMessage('edit'),
			remove: formatMessage('remove'),
		},
		PasswordField: {
			show: formatMessage('show'),
			hide: formatMessage('hide'),
		},
		RadioSelectionRow: {
			edit: formatMessage('edit'),
		},
	})

	const [labelConfig, setLabelConfig] = useState<UILabelLibrary>(resolveLibraryTranslations())

	useEffect(() => {
		setupDataDog(locale)
		setLabelConfig(resolveLibraryTranslations())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale])

	return (
		<>
			<AbTestProvider>
				<CartProvider>
					<LoyaltyProvider>
						<WishlistProvider>
							<ThirdPartyPaymentProvider>
								<QuickAddToCartProvider>
									<PreferredSizesProvider>
										<LayoutMeasurementProvider>
											<IntlProvider locale={locale} defaultLocale={'en-us'}>
												<AuthModalProvider>
													<UIComponentsProvider fonts={fontConfig} labelConfig={labelConfig}>
														{children}
														{footer}
													</UIComponentsProvider>
													<BfxScript />
													<Suspense>
														<Modals />
													</Suspense>

													{getPublicConfig().toolkit.enabled && <Toolkit />}

													<Suspense>
														<Loading />
													</Suspense>
												</AuthModalProvider>
											</IntlProvider>
										</LayoutMeasurementProvider>
									</PreferredSizesProvider>
								</QuickAddToCartProvider>
							</ThirdPartyPaymentProvider>
						</WishlistProvider>
					</LoyaltyProvider>
				</CartProvider>
			</AbTestProvider>
		</>
	)
}
