'use client'

import { memo, useEffect, useMemo, useState } from 'react'
import Cookies from 'universal-cookie'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'
import { useLocale } from '~/components/hooks/useLocale'
import styles from './CookieConsent.module.scss'
import { useAnalytics } from '~/components/hooks/useAnalytics'
import { safeJsonParse } from '~/lib/utils'

const consent = 'teconsent'

// NOTE: This should technically be a `CustomEvent`, but Node.js only supports that in version >= 19
export const HideBannerEvent = new Event('hidebanner', { bubbles: true })
export const consentCookieName = 'consentBannerAcknowledged'
export const consentCookieLink = <div key="consent" id={consent} />

const CookieConsent = memo(function ConsentCookieBanner() {
	const { analyticsManager } = useAnalytics()
	const cookies = useMemo(() => new Cookies(), [])
	const consentBannerAcknowledged = Boolean(cookies.get(consentCookieName))
	const { measureConsentBannerHeight, setConsentBannerElement } = useLayoutMeasurement()

	/* -------------------- TrustArc Configuration -------------------- */
	const locale = useLocale()
	// state is used to load the TrustArc script only once per page.
	const [init, setInit] = useState(false)

	const trustArcUrl = useMemo(() => {
		const url = new URL(`https://consent.trustarc.com/notice?domain=underarmour.com&c=${consent}&text=true&gtm=1`)
		url.searchParams.set('js', 'nj')
		url.searchParams.set('noticeType', 'bb')

		const [language] = locale.split('-')
		url.searchParams.set('language', language)
		return url.toString()
	}, [locale])

	/** ---------- Load TrustArc Script -----------------  */

	/**
	 * init state is used to make it pure; that ensures it'll only ever fire once.
	 * Without this state and directly using trustArcUrl in the if codition of next useEffect which loads script,
	 * it'll sometimes request twice, resulting in multiple banners.
	 */
	useEffect(() => setInit(!!trustArcUrl), [trustArcUrl])

	/**
	 * We do not want to use Next/Script to load the TrustArc script as of now.
	 * Next.js will ensure the script will only load only once, even if a user navigates between multiple pages.
	 * As a result, the initialization script (trustArc) is invoked only once,
	 * which is resulting in CookiePreferences link disapplearing when user moves to a different layout.
	 */
	useEffect(() => {
		// Load the script when trustArcUrl is initialized
		if (!init) {
			return undefined
		}

		const script = document.createElement('script')

		script.src = trustArcUrl
		script.async = true
		script.defer = true

		document.body.appendChild(script)

		return () => {
			// Remove the script when component un-mounts (a different layout is rendered)
			document.body.removeChild(script)
			return undefined
		}
	}, [init, trustArcUrl])

	/* -------------------- Logic for Mounting TrustArc Consent Banner -------------------- */
	const consentBannerId = 'consent_blackbar'
	useEffect(() => {
		if (consentBannerAcknowledged) return undefined

		const closeIconId = 'truste-consent-close'
		const consentBanner = document.getElementById(consentBannerId) as HTMLDivElement
		setConsentBannerElement(consentBanner)

		// Improve TrustArc elements onMount
		const observer = new MutationObserver((mutationList) => {
			mutationList.forEach((mutation) => {
				const addedNodes = Array.from(mutation.addedNodes) as HTMLElement[]
				const content = addedNodes.find((node) => node instanceof HTMLDivElement && node.id === 'truste-consent-track')
				if (!content) return // These are not the nodes you're looking for

				// Protect users from unsafe links
				content.querySelectorAll("[target='_blank']").forEach((a) => a.setAttribute('rel', 'noreferrer'))

				// Clarify all `button` types to avoid unexpected bugs
				content.querySelectorAll('button').forEach((button) => button.setAttribute('type', 'button'))

				// Don't navigate user anywhere when they close the consent banner
				const closeElement = content.querySelector<HTMLAnchorElement>(`#${closeIconId}`)
				closeElement?.addEventListener('click', (e) => e.preventDefault(), { once: true })

				measureConsentBannerHeight()

				observer.disconnect() // We only need the observer when the TrustArc Banner first mounts
			})
		})

		// Indicate when the close icon was clicked
		function handleClick(event: MouseEvent): void {
			const closeIcon = (event.target as HTMLElement).closest(`#${closeIconId}`)
			if (!closeIcon) return

			closeIcon.dispatchEvent(HideBannerEvent)
			consentBanner.removeEventListener('click', handleClick) // Listener is useless after banner is hidden
		}

		observer.observe(consentBanner, { childList: true })
		consentBanner.addEventListener('click', handleClick)

		return () => {
			observer.disconnect()
			consentBanner.removeEventListener('click', handleClick)
		}
	}, [consentBannerAcknowledged, measureConsentBannerHeight, setConsentBannerElement])

	/* -------------------- Logic for Hiding the Consent Banner -------------------- */
	useEffect(() => {
		if (consentBannerAcknowledged) return document.getElementById(consentBannerId)?.setAttribute('hidden', '')
		// Listener is only needed once since it is useless after banner is hidden
		document.addEventListener(HideBannerEvent.type, handleHideBanner, { once: true })
		return () => document.removeEventListener(HideBannerEvent.type, handleHideBanner)

		function handleHideBanner() {
			const consentBanner = document.getElementById(consentBannerId)
			cookies.set(consentCookieName, String(true))
			consentBanner?.setAttribute('hidden', '')
			measureConsentBannerHeight()
		}
	}, [cookies, consentBannerAcknowledged, measureConsentBannerHeight])

	useEffect(() => {
		if (consentBannerAcknowledged) return undefined

		window.addEventListener('message', handleMessage)
		return () => window.removeEventListener('message', handleMessage)

		function handleMessage(event: MessageEvent): void {
			if (event.origin !== 'https://consent-pref.trustarc.com') return // Only accept TrustArc messages
			const data = safeJsonParse<{ message?: string }>(event.data)
			if (data?.message !== 'submit_preferences') return
			document.dispatchEvent(HideBannerEvent)
			window.removeEventListener('message', handleMessage) // Listener is useless after banner is hidden
		}
	}, [consentBannerAcknowledged])

	useEffect(() => {
		function handleMessage(event: MessageEvent) {
			if (event.origin !== 'https://consent-pref.trustarc.com') return
			const data = safeJsonParse<{ message?: string; source?: string }>(event.data)
			// This is needed for legal/privacy compliance
			if (data?.source === 'preference_manager' && data?.message === 'submit_preferences') {
				window.location.reload()
			}
			if (data?.message !== 'cm_loading') return
			analyticsManager.fireModalOpened({
				site_modal: 'privacy info banner',
			})
		}

		window.addEventListener('message', handleMessage, false)
		return () => window.removeEventListener('message', handleMessage)
	}, [analyticsManager])

	return <div id={consentBannerId} className={styles['cookie-consent-banner']} />
})

export default CookieConsent
